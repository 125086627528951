import React, {Component} from 'react';

export default class Download extends React.Component {
    constructor(props) {
        super(props);
        this.state = {clicked: false};
        this.clicked = this.clicked.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.state.clicked) {
            this.setState({clicked: false});
        }
    }

    clicked() {
        if (this.state.clicked == false) {
            this.setState({clicked: true});
        } else {
            this.setState({clicked: false});
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef}>
                <button
                    className="focus:outline-none hover:bg-purple-700 hover:text-white focus:outline-none disabled:opacity-20 disabled:bg-transparent disabled:text-black"
                    onClick={this.clicked} disabled={this.props.landing}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="h-8 w-8 sm:h-10 sm:w-10 xl:h-12 xl:w-12 my-1 sm:my-2"
                         viewBox="0 0 20 20"
                         fill="currentColor">
                        <path fillRule="evenodd"
                              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                              clipRule="evenodd"/>
                    </svg>
                </button>
                {this.state.clicked ?
                    <div
                        className="absolute bottom-12 sm:bottom-16 lg:bottom-20 w-10 sm:w-12 lg:w-16 my-1 lg:my-0 h-14 sm:h-16 lg:h-18 flex flex-col z-50"
                        style={{backgroundColor: this.props.backgroundColor}}>
                        <button
                            className="text-xl sm:text-2xl xl:text-3xl hover:bg-purple-700 hover:text-white focus:outline-none"
                            onClick={() => this.props.downloadSVG()}>.SVG
                        </button>
                        <button
                            className="text-xl sm:text-2xl xl:text-3xl hover:bg-purple-700 hover:text-white focus:outline-none"
                            onClick={() => this.props.downloadPNG()}>.PNG
                        </button>
                    </div> : <div/>
                }
            </div>
        );
    }

}