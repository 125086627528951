import React, {Component} from 'react';


export default class SizePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {clicked: false, size_name: 'Fit to screen'};
        this.clicked = this.clicked.bind(this);
        this.sizeChange = this.sizeChange.bind(this)
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.state.clicked) {
            this.setState({clicked: false});
        }
    }

    clicked() {
        console.log('clicked')
        if (this.state.clicked == false) {
            this.setState({clicked: true});
        } else {
            this.setState({clicked: false});
        }
    }

    sizeChange(size_name, fit_screen, width, height) {
        this.setState({size_name: size_name});
        this.props.changeSize(fit_screen, width, height);
    }

    render() {
        return (
            <div ref={this.wrapperRef}>
                <button disabled={this.props.landing} onClick={this.clicked}
                        className="pt-2.5 lg:pt-3 xl:2.5 w-32 sm:w-40 lg:w-48 text-l sm:text-xl xl:text-2xl hover:text-purple-700 focus:outline-none flex flex-row disabled:opacity-20 disabled:bg-transparent disabled:text-black">
                    {this.state.size_name}
                    {this.state.clicked ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-0.5 sm:mt-1 lg:mt-0 xl:mt-1 lg:h-8 lg:w-8" viewBox="0 0 20 20"
                             fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                  clipRule="evenodd"/>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-0.5 sm:mt-1 lg:mt-0 xl:mt-1 lg:h-8 lg:w-8" viewBox="0 0 20 20"
                             fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"/>
                        </svg>}
                </button>
                {this.state.clicked ?
                    <div className="absolute right-10 top-16 sm:top-20 text-lg sm:text-xl xl:text-2xl flex flex-col -m-6"
                         style={{backgroundColor: this.props.backgroundColor}}>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == true) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('Fit to screen', true, window.innerWidth,
                                Math.trunc(window.innerHeight * (5 / 6)))}>Fit to
                            screen {window.innerWidth}x{Math.trunc(window.innerHeight * (5 / 6))}
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${((this.state.fit_screen == false) && (this.state.width == 2480) && (this.state.height == 3508)) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('A4 Portrait', false, 2480, 3508)}>A4 Portrait 2480x3508
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 3508 && this.props.height == 2480) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('A4 Landscape', false, 3508, 2480)}>A4 Landscape 3508x2480
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 400 && this.props.height == 400) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('Profile M', false, 400, 400)}>Profile 400x400
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 800 && this.props.height == 800) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('Profile L', false, 800, 800)}>Profile 800x800
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 1080 && this.props.height == 1080) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('IG Square', false, 1080, 1080)}>IG Square 1080x1080
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 1200 && this.props.height == 1200) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('FB Square', false, 1200, 1200)}>FB Square 1200x1200
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 1080 && this.props.height == 1920) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('FB/IG Story', false, 1080, 1920)}>FB/IG Story 1080x1920
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 820 && this.props.height == 360) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('FB Cover', false, 820, 360)}>FB Cover 820x360
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 1200 && this.props.height == 630) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('Event/Post/Ad', false, 1200, 630)}>Event/Post/Ad 1200x630
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 1640 && this.props.height == 856) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('FB Group', false, 1640, 856)}>FB Group Cover 1640x856
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 1500 && this.props.height == 500) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('Twitter Header', false, 1500, 500)}>Twitter Header 1500x500
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 1024 && this.props.height == 512) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('Twitter Post', false, 1024, 512)}>Twitter Post 1024x512
                        </button>
                        <button
                            className={`hover:bg-purple-700 hover:text-white focus:outline-none ${(this.props.fit_screen == false && this.props.width == 2560 && this.props.height == 1440) ? "bg-purple-700 text-white" : "text-black"}`}
                            onClick={() => this.sizeChange('YouTube', false, 2560, 1440)}>YouTube Channel
                            2560x1440
                        </button>

                    </div> : <div/>}
            </div>
        );
    }

}