import React, {Component} from 'react';

//<label className="block text-xl mb-2 text-black">
//  Type your text here
//</label>

export default class TextForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.textInput = React.createRef();
    }

    componentDidMount() {
        this.textInput.current.focus();
    }

    handleChange(event) {
        //this.setState({value: event.target.value});
        const text = event.target.value;
        this.props.onChange(text);
    }


    render() {
        return (
            <form>
          <textarea disabled={this.props.landing} ref={this.textInput} className="bg-transparent text-xl sm:text-3xl xl:text-4xl border-none bg-none w-full h-8 sm:h-10 xl:h-12 outline-none
          text-gray-400 focus:text-black resize-none" type="text" value={this.props.text} onChange={this.handleChange}
                    placeholder={this.props.landing ? "" : "Type your text here.."}/>
            </form>

        );
    }
}