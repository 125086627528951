import React, {Component} from 'react';

export default class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {clicked: false};
        this.clicked = this.clicked.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.state.clicked) {
            this.setState({clicked: false});
        }
    }

    clicked() {
        if (this.state.clicked == false) {
            this.setState({clicked: true});
        } else {
            this.setState({clicked: false});
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef}>
                <button
                    className="focus:outline-none hover:bg-purple-700 hover:text-white focus:outline-none"
                    onClick={this.clicked}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="h-8 w-8 sm:h-10 sm:w-10 xl:h-12 xl:w-12 my-1 sm:my-2" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                    </svg>
                </button>
                {this.state.clicked ?
                    <div
                        className="absolute bottom-12 sm:bottom-16 lg:bottom-20 right-4 sm:right-8 w-10 sm:w-12 lg:w-16 my-1 lg:my-0 h-14 sm:h-16 lg:h-18 flex flex-col z-50"
                        style={{backgroundColor: this.props.backgroundColor}}>
                        <a target="_blank"
                           className="text-xl sm:text-2xl xl:text-3xl hover:bg-purple-700 hover:text-white focus:outline-none"
                           href="https://join.slack.com/t/chaosmachineworkspace/shared_invite/zt-qqaa5avn-6J0WAr02CW36qCJ~zYyq1A">Slack
                        </a>
                        <a target="_blank"
                           className="text-xl sm:text-2xl xl:text-3xl hover:bg-purple-700 hover:text-white focus:outline-none"
                           href="mailto:alex@chaosmach.com">email
                        </a>
                    </div> : <div/>
                }
            </div>
        );
    }

}