import React, {Component} from 'react';

export default class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {clicked: false};
        this.clicked = this.clicked.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.state.clicked) {
            this.setState({clicked: false});
        }
    }

    clicked() {
        if (this.state.clicked == false) {
            this.setState({clicked: true});
        } else {
            this.setState({clicked: false});
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef}>
                <button onClick={this.clicked} className="text-xl sm:text-3xl xl:text-4xl hover:text-purple-700 focus:outline-none">
                    Background
                </button>
                {this.state.clicked ?
                    <div className="absolute bottom-32 w-40 h-16 flex flex-wrap">
                        <button className={`h-8 w-8 bg-white focus:outline-none hover:border-4 hover:border-purple-700  
                                            ${(this.props.color == 'white') ? " border-4 border-black" : " border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('white')}/>
                        <button className={`h-8 w-8 fdefed focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#fdefed') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#fdefed')}/>
                        <button className={`h-8 w-8 fdedfb focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#fdedfb') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#fdedfb')}/>
                        <button className={`h-8 w-8 fdf7ed focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#fdf7ed') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#fdf7ed')}/>
                        <button className={`h-8 w-8 edf3fd focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#edf3fd') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#edf3fd')}/>
                        <button className={`h-8 w-8 edfdf7 focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#edfdf7') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#edfdf7')}/>
                        <button className={`h-8 w-8 a4ddd1 focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#a4ddd1') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#a4ddd1')}/>
                        <button className={`h-8 w-8 f8b7b4 focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#f8b7b4') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#f8b7b4')}/>
                        <button className={`h-8 w-8 f1dabb focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#f1dabb') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#f1dabb')}/>
                        <button className={`h-8 w-8 d1d1d1 focus:outline-none hover:border-4 hover:border-purple-700 
                                            ${(this.props.color == '#d1d1d1') ? "border-4 border-black" : "border-2 border-gray-400"}`}
                                onClick={() => this.props.changeBackground('#d1d1d1')}/>
                    </div> : <div/>
                }
            </div>
        );
    }
}